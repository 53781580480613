import React from 'react'

function ClientRequir() {
  return (
    <div>



<section class="medium-padding100 align-center video-section video-box">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			    <div class="crumina-module crumina-heading">
				   <h2 class="heading-title">Client's  <span class="c-primary">Experience/Feedback:</span>
				</h2>
				</div>
				<div class="seccess-story-video">
						{/* <img src="img/video-thumb-seccess-story.jpg" alt="video"/> */}
						<iframe width="100%" height="500" src="https://www.youtube.com/embed/KfKLmd3ulhs?rel=0"></iframe>
				</div>
				
			</div>
		</div>
	</div>
</section>


        
	{/* <section class="medium-padding100 client-req-page">
		<div class="container">
			<div class="crumina-module crumina-heading">
				<h2 class="heading-title text-center">Client's Requirement</h2>
			</div>
			<div class="row">
				<div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
					<h3>Claritas est etiam processus dynamicus, sequitur lectorum est notare quam littera gothica</h3>
					<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
						laoreet dolore magna aliquam erat volutpat. Investigationes demonstraverunt lectores legere me lius
						quod ii legunt saepius. Investigationes demonstraverunt lectores legere me lius quod. Mirum est
						notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas
						humanitatis per seacula quarta decima et quinta decima nobis et iusto odio dignissim qui blandit
						praesent luptatum zzril delenit.
					</p>
					<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
						ut laoreet dolore magna aliquam erat volutpat.
					</p>
				</div>
				<div class="col-lg-2 col-lg-offset-1 col-md-3 col-sm-6 col-xs-6">
					<div class="text-box">
						<h6 class="title">Categories</h6>
						<ul>
							<li>App Design</li>
							<li>UI Kit</li>
						</ul>
					</div>
					<div class="text-box">
						<h6 class="title">What We Did</h6>
						<ul>
							<li>Application</li>
							<li>User Interface</li>
							<li>Photography</li>
							<li>Marketing</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-6 col-xs-6">
					<div class="text-box">
						<h6 class="title">Year</h6>
						<ul>
							<li>2017</li>
						</ul>
					</div>
					<div class="text-box">
						<h6 class="title">Client</h6>
						<ul>
							<li>Utouch Inc.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section> */}
    </div>
  )
}

export default ClientRequir