import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
function CoreServices() {
  return (
    <div>
      <section class="medium-padding100 hm-core-secvice">
        <div class="container core-secvice-sec">
          
          <div class="text-center title_core">
            <h1 class="display-4">
              Our Core Services
            </h1>
            <h6>HIRE A REMOTE EMPLOYEE FOR ANY ROLE!</h6>
          </div>
          <div class="row">

            <div class="col-lg-3 col-md-4 col-12 col-xxl-3">
              <div class="crumina-module crumina-info-box info-box--standard-hover">
              <div class="shape1">
                <img src="img/intro-v1-shape1.png" alt="#"/>
              </div>
                <div class="info-box-image">
                    <img class="utouch-icon" src="img/Custom-Software-Development.png" alt="smartphone"/>
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    Custom Software Development
                  </a>
                  {/*<p class="info-box-text">
                    Having an automated process according to our Business
                    needs makes our life and work much easier. TGAYS
                    Technology has an extensive experience in creating high
                    performance, feature-packed customized web applications
                    according to specific business needs.
                  </p>*/}
                </div>
              </div>
            </div>
            
            <div class="col-lg-3 col-md-4 col-12 col-xxl-3">
              <div class="crumina-module crumina-info-box info-box--standard-hover">
                <div class="shape1">
                  <img src="img/intro-v1-shape1.png" alt="#"/>
                </div>
                <div class="info-box-image">
                  <img class="utouch-icon" src="img/Mobile-App-Development.png" alt="smartphone"
                  />
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    Mobile App Development
                  </a>
                  {/*<p class="info-box-text">
                    There are more than 3 billion mobile users in the world.
                    If you have a great idea, your customers are waiting to
                    download an interactive mobile app that makes their life
                    easier. All you need is the right company to take your
                    idea to the next level.
                  </p>*/}
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12 col-xxl-3">
              <div class="crumina-module crumina-info-box info-box--standard-hover">
                <div class="shape1">
                  <img src="img/intro-v1-shape1.png" alt="#"/>
                </div>
                <div class="info-box-image">
                  <img class="utouch-icon" src="img/GD_design.png" alt="smartphone" />
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    Graphics & Website Design
                  </a>
                  {/*<p class="info-box-text">
                    A company's credibility is judged based on how the
                    website looks and loads. So, we have to be very careful
                    in designing a user-friendly, easy to navigate, device
                    responsive and fast loading website.
                  </p>*/}
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12 col-xxl-3">   
              <div class="crumina-module crumina-info-box info-box--standard-hover">
                <div class="shape1">
                  <img src="img/intro-v1-shape1.png" alt="#"/>
                </div>
                <div class="info-box-image">
                  <img class="utouch-icon" src="img/mvp_icon.png" alt="smartphone" />
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    IT Staffing
                  </a>
                  {/*<p class="info-box-text">
                    A company's credibility is judged based on how the
                    website looks and loads. So, we have to be very careful
                    in designing a user-friendly, easy to navigate, device
                    responsive and fast loading website.
                  </p>*/}
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12 col-xxl-3">
              <div class="crumina-module crumina-info-box info-box--standard-hover">
                <div class="shape1">
                  <img src="img/intro-v1-shape1.png" alt="#"/>
                </div>
                <div class="info-box-image">
                  <img class="utouch-icon" src="img/CRM.png" alt="smartphone" />
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    CRM/SaaS/ERP Development
                  </a>
                  {/*<p class="info-box-text">
                    A company's credibility is judged based on how the
                    website looks and loads. So, we have to be very careful
                    in designing a user-friendly, easy to navigate, device
                    responsive and fast loading website.
                  </p>*/}
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12 col-xxl-3"> 
              <div class="crumina-module crumina-info-box info-box--standard-hover">
                <div class="shape1">
                  <img src="img/intro-v1-shape1.png" alt="#"/>
                </div>
                <div class="info-box-image">
                  <img class="utouch-icon" src="img/Digital_Marketing.png" alt="smartphone" />
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    Digital Marketing
                  </a>
                  {/*<p class="info-box-text">
                    A company's credibility is judged based on how the
                    website looks and loads. So, we have to be very careful
                    in designing a user-friendly, easy to navigate, device
                    responsive and fast loading website.
                  </p>*/}
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12 col-xxl-3">
              <div class="crumina-module crumina-info-box info-box--standard-hover">
                <div class="shape1">
                  <img src="img/intro-v1-shape1.png" alt="#"/>
                </div>
                <div class="info-box-image">
                  <img class="utouch-icon" src="img/IT-Consulting.png" alt="smartphone" />
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    IT Consulting
                  </a>
                  {/*<p class="info-box-text">
                    A company's credibility is judged based on how the
                    website looks and loads. So, we have to be very careful
                    in designing a user-friendly, easy to navigate, device
                    responsive and fast loading website.
                  </p>*/}
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12 col-xxl-3">
              <div class="crumina-module crumina-info-box info-box--standard-hover">
                <div class="shape1">
                  <img src="img/intro-v1-shape1.png" alt="#"/>
                </div>
                <div class="info-box-image">
                  <img
                    class="utouch-icon" src="img/offshop.png" alt="smartphone"
                  />
                </div>
                <div class="info-box-content text-center">
                  <a href="#" class="h5 info-box-title">
                    OffShore Development
                  </a>
                  {/*<p class="info-box-text">
                    A company's credibility is judged based on how the
                    website looks and loads. So, we have to be very careful
                    in designing a user-friendly, easy to navigate, device
                    responsive and fast loading website.
                  </p>*/}
                </div>
              </div>
            </div>
          </div> 

            {/* <div class="col-lg-6 col-md-12 col-12 col-xxl-6">
              <img
                src="img/core-service-img.png"
                class="core-service-img"
                alt="core-services"
              />
            </div> */}
          </div>
      </section>

      <section class="background-contain pt100 hm-about-sec">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="crumina-module about-video">
                <div class="about-thumb">
                  <div class="about_img1">
                    <img src="img/3.png" alt="img-1" />
                  </div>
                  <div class="about_img2">
                    <img src="img/8.png" alt="img-2" />
                    </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 about-us-sec">
              <div class="crumina-module crumina-heading">
                <div class="about-us-hedding">
                  <h2 class="heading-title">
                    <span class="c-primary">About</span> us
                  </h2>
                  <img class="cloud" src="img/clouds8.png" alt="cloud" />
                </div>
                <p class="heading-text">
                Welcome to TGAYS Technology, your premier partner in digital innovation 
                and technology solutions. With a dedicated team of industry experts, 
                we specialize in crafting cutting-edge websites, mobile applications, 
                Software as a Service (SaaS) products, and Customer Relationship 
                Management (CRM) systems that drive business success.
                </p>
                <p>
                Our mission is to empower businesses by transforming their digital 
                presence and streamlining their operations through innovative 
                technology solutions. We strive to deliver exceptional products 
                and services, leveraging the latest technologies and tools, foster 
                long-term partnerships, and ensure our clients stay ahead in an 
                ever-evolving digital landscape.
                </p>
              </div>
                <Link to='/company'>
                  <span class="title">READ MORE</span>
                </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="crumina-module crumina-clients background-contain bg-secondary-color featured-clients-section">
        <div className="container">

        <div className="crumina-module crumina-clients-heading">
          <h2 className="heading-title">Our Clients Worldwide</h2>
        </div>

        <div class="client_section_row">
            <div class="row">

            <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/mahindra-Logo.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Mahindra First Choice</h5>
                      <h6>Location: India</h6>
                    </div>
                  </div>
                </div>
              </div>

            <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/QS-Logo.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>QS</h5>
                      <h6>Location: India & UK</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/gurbuz-winery.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Gurbuz Winery & Restaurant</h5>
                      <h6>Location: Turkey</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/worthmetrix_logo-client.png" />
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Worthmetrix LLC</h5>
                      <h6>Location: USA</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/Mega_Sign_Logo_Web-Recovered.png" />
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Mega LED Technology</h5>
                      <h6>Location: USA</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/log_jlc.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Just Like Children</h5>
                      <h6>Location: Canada</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/s.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Intensity</h5>
                      <h6>Location: Estonia</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/Techlab.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Techlabs Systems Ltd</h5>
                      <h6>Location: Kenya</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/evolveAi.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Evolve AI</h5>
                      <h6>Location: USA</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/tax-roll-logo.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>TM Consulting, Inc</h5>
                      <h6>Location: USA</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/kia-gts-client.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>KIA-GTS LLC</h5>
                      <h6>Location: Dubai</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/gogiro-white.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Gogiro Internet Group, Inc</h5>
                      <h6>Location: USA</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/business_logo.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Enovative Technologies, LLC</h5>
                      <h6>Location: Austria</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/SEALIONPRO.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>SEALIONPRO LTD</h5>
                      <h6>Location: UK</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/creative-intelligence-logo.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Creative Intelligence Agency</h5>
                      <h6>Location: USA</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/Image3DConversion.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Image3DConversion Pvt Ltd</h5>
                      <h6>Location: India</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/SGTREK-logo_client.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>SGTrek Pte. Ltd</h5>
                      <h6>Location: Singapore</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/CITY-TEHELKA-LOGO.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>JMDK Media Pvt. Ltd.</h5>
                      <h6>Location: India</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/gi_group.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>GI Group Holding</h5>
                      <h6>Location: India</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/bolton_client_logo.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>Bolton Travel Ltd</h5>
                      <h6>Location: UK, India</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-3">
                <div class="client_box">
                  <div class="client-img">
                    <img src="img/bid_client.png"/>
                  </div>
                  <div class="client_dt">
                    <div class="name-cl">
                      <h5>BidRaves Inc</h5>
                      <h6>Location: USA</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </div>
  );
}

export default CoreServices;
