import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as API from "../components/API/Fetch";

const Header = () => {
  const [data, setData] = useState("");
  const [link, setLink] = useState("");
  function updateHeader() {
    if (data == "block") {
      setData("none");
    } else {
      setData("block");
    }
  }

  const [toggler, setToggler] = useState("");
  const hideShow = (event) => {
    const id = event.currentTarget.id;
    console.log(id, toggler);
    if (toggler == "block") {
      setToggler("none");
      document.getElementById(id).children[0].style.display = "none";
    } else {
      setToggler("block");
      document.getElementById(id).children[0].style.display = "block";
    }
  };
  // function hideShow(event) {
  //   const id = event.target.id;
  //   console.log(id, toggler);
  //   if (toggler == "block") {
  //     setToggler("none");
  //     document.getElementById(id).style.display = "none";
  //   } else {
  //     setToggler("block");
  //     document.getElementById(id).style.display = "block";
  //   }
  // }

  useEffect(() => {
    const data = { token: "token" };
    API.get(data, "user/social-link").then((res) => {
      if (res) {
        console.log(res);
        setLink(res.result);
      } else {
        console.log("token mismatch");
      }
    });
  }, []);
  return (
    <div className="main_header">
      <header className="header" id="site-header">
        <div className="header-lines-decoration">
          <span className="bg-secondary-color"></span>
          <span className="bg-blue"></span>
          <span className="bg-blue-light"></span>
          <span className="bg-orange-light"></span>
          <span className="bg-red"></span>
          <span className="bg-green"></span>
          <span className="bg-secondary-color"></span>
        </div>

        <div className="container">
          <div className="header-content-wrapper">
            

            <div className="phone-mail-sec">
              <ul>
                <li>
                  <a title="">
                    <span><ion-icon name="mail-open-outline"></ion-icon></span>
                    {/* <img
                      src={process.env.PUBLIC_URL + "/img/envelope-open.png"}
                      alt=""
                    />{" "} */}
                    info@tgaystechnology.com
                  </a>
                </li>

                <li className="main_phone_num">
                  <img
                    src={process.env.PUBLIC_URL + "/img/ind-mp.png"}
                    alt="ind"
                  />
                  <a title="">(+91) 9319239730</a>
                </li>
              </ul>
            </div>

            {/* <div className="top-head-social-icon">
              <ul>
                <li>
                  <Link to='/popup'>
                    <button type="" class="btn js-message-popup top_header_btn">Send Us Your Requirement</button>
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </header>

      <div className="Navigation-sec">
        <div className="container">
          <div class="nav_box">
            <div className="site-logo">
              <Link to="/" className="">
                <img src={process.env.PUBLIC_URL + "/img/logo-tgys.png"} alt="techsgeeks" />
              </Link>
              
            </div>
            <nav id="primary-menu" className="primary-menu">
              <button
                className="navbar-toggler react-toggle"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={updateHeader}
              >
                <span className="navbar-toggler-icon">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </span>
              </button>
              {/* <ul className="primary-menu-menu react-menu-section" style={{ display: data }}></ul> */}
              <ul
                className="primary-menu-menu react-menu-section"
                style={{ display: data }}
              >
                {/* <li className="menu-item-has-children">
                  <Link to="/">home</Link>
                </li> */}

                <li className="hire-nw" id="showHideHire" onClick={hideShow}>
                  hire
                  <ul className="sub-menu hire-nw-submenu">
                    <li>
                      <Link to="/hire-php">codeigniter developer</Link>
                    </li>

                    <li>
                      <Link to="/hire-laravel">laravel developer</Link>
                    </li>

                    <li>
                      <Link to="/hire-node">node developer</Link>
                    </li>
                    <li>
                      <Link to="/hire-react">react.js developer</Link>
                    </li>

                    <li>
                      <Link to="/hire-reactnative">react native developer</Link>
                    </li>

                    <li>
                      <Link to="/hire-wordPress">wordpress developer</Link>
                    </li>

                    <li>
                      <Link to="/hire-ui-ux-designer">UI/UX designer</Link>
                    </li>
                    {/* <li>
                      <Link to="#">PYTHON DEVELOPER</Link>
                    </li> */}
                    {/* <li>
                      <Link to="#">SALESFORCE DEVELOPER</Link>
                    </li> */}
                  </ul>
                </li>

                <li className="at" id="showHideAt" onClick={hideShow}>
                  about
                  <ul className="sub-menu at-sub-menu">
                    <li>
                      <Link to="/company">company</Link>
                    </li>
                    <li>
                      <Link to="/why-us">why TGAYS?</Link>
                    </li>
                    <li>
                      <Link to="/our-achievment">achievments</Link>
                    </li>
                  </ul>
                </li>

                <li
                  className="menu-item-has-mega-menu menu-item-has-children prtfolio"
                  id="showHidePort"
                  onClick={hideShow}
                >
                  portfolio
                  <div className="megamenu with-products prtfolio-mega">
                    <div className="megamenu-row">
                      <div className="col3" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-dark"></div>
                            <img
                              src={process.env.PUBLIC_URL + "/img/e-commerce.png"}
                              alt="product"
                            />
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">E-COMMERCE</h6>
                            <Link
                              to="/ecommerce-portfolio"
                              className="more-arrow"
                            >
                              <span>View Portfolio</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col3" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-blue-dark"></div>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/product-item3.jpg"
                              }
                              alt="product"
                            />
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">WEB APP</h6>
                            <Link to="/web-app-portfolio" className="more-arrow">
                              <span>View Portfolio</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col3" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-blue"></div>
                            <img
                              src={process.env.PUBLIC_URL + "/img/mobile-app.png"}
                              alt="product"
                            />
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">MOBILE APP</h6>
                            <Link
                              to="/mobile-app-portfolio"
                              className="more-arrow"
                            >
                              <span>View Portfolio</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col3" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-orange"></div>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/product-item2.jpg"
                              }
                              alt="product"
                            />
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">WEBSITE</h6>
                            <Link to="/website-portfolio" className="more-arrow">
                              <span>View Portfolio</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col3" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-blue-dark"></div>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/product-item4.jpg"
                              }
                              alt="product"
                            />
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">DIGITAL MARKETING</h6>
                            <Link to="/digital-marketing" className="more-arrow">
                              <span>VIEW PORTFOLIO</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </li>

                <li className="servic-nv" id="shodHideServe" onClick={hideShow}>
                  services
                  <ul className="sub-menu servic-nv-submenu">
                    <li>
                      <Link to="/web-app-development">web development</Link>
                    </li>

                    <li>
                      <Link to="/mobile-app">mobile app development</Link>
                    </li>

                    <li>
                      <Link to="/sale-force-crm">salesforce crm development</Link>
                    </li>

                    <li>
                      <Link to="/customized-crm-development">
                        customized crm development
                      </Link>
                    </li>

                    <li>
                      <Link to="/business-intelligence-reporting">
                        business intelligence & reporting
                      </Link>
                    </li>

                    <li>
                      <Link to="/full-cycle-product-development">
                        full-cycle product development
                      </Link>
                    </li>

                    <li>
                      <Link to="/ecommerce-solution"> 
                        e-commerce web and mobile app development
                      </Link>
                    </li>

                    <li>
                      <Link to="/progressive-web-app">
                        progressive web app development
                      </Link>
                    </li>

                    <li>
                      <Link to="/digital-marketing">
                        Digital Marketing(SEO, SEM, PPC)
                      </Link>
                    </li>

                    {/* <li>
                      <Link to="/web-app-service">
                        {" "}
                        WEBSITE DESIGN & DEVELOPMENT
                      </Link>
                    </li> */}

                    {/* <li>
                      <Link to="/website-maintanence">
                        WEBSITE MAINTENANCE & SECURITY
                      </Link>
                    </li> */}

                    {/* <li>
                      <Link to="/magneto-development">
                        MAGENTO FRONT-END AND BACK-END DEVELOPMENT
                      </Link>
                    </li> */}
                    {/* 
                    <li>
                      <Link to="/wordpress-development">
                        WORDPRESS DEVELOPMENT
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li
                  className="menu-item-has-mega-menu menu-item-has-children scss-stories"
                  id="showHideSuccess"
                  onClick={hideShow}
                >
                  success stories
                  <div className="megamenu with-products scss-stories-mega-menu">
                    <div className="megamenu-row">
                      <div className="col4" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-blue"></div>
                            <Link to="/served-more" className="more-arrow">
                              <img
                                src={process.env.PUBLIC_URL + "/img/rich.png"}
                                alt="product"
                              />
                            </Link>
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">
                              10 years of working together{" "}
                            </h6>
                            <Link to="/served-more" className="more-arrow">
                              <span>View Details</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col4" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-violet"></div>
                            <Link
                              to="/ecommerce-complete-solution"
                              className="more-arrow"
                            >
                              <img
                                src={process.env.PUBLIC_URL + "/img/franc.jpg"}
                                alt="product"
                              />
                            </Link>
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">
                              Collaboration with KIA-GTS LLC Dubai
                            </h6>
                            <Link
                              to="/ecommerce-complete-solution"
                              className="more-arrow"
                            >
                              <span>View Details</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col4" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-red"></div>
                            <Link
                              to="/service-provider-app"
                              className="more-arrow"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/worthmetrix.png"
                                }
                                alt="product"
                              />
                            </Link>
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">
                              Worthmetrix - Business Worth Calculator
                            </h6>
                            <Link
                              to="/service-provider-app"
                              className="more-arrow"
                            >
                              <span>View Details</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col4" data-mh="product-item">
                        <div className="product-item">
                          <div className="product-item-thumb">
                            <div className="square-colored bg-product-blue-dark"></div>
                            <Link to="/digital-art-club" className="more-arrow">
                              <img
                                src={process.env.PUBLIC_URL + "/img/thomas.png"}
                                alt="product"
                              />
                            </Link>
                          </div>
                          <div className="product-item-content">
                            <h6 className="title">DIGITAL ART CLUB</h6>
                            <Link to="/digital-art-club" className="more-arrow">
                              <span>View Details</span>
                              <div className="btn-next">
                                <svg className="utouch-icon icon-hover utouch-icon-arrow-right-1">
                                  <use xlinkHref="#utouch-icon-arrow-right-1"></use>
                                </svg>
                                <svg className="utouch-icon utouch-icon-arrow-right1">
                                  <use xlinkHref="#utouch-icon-arrow-right1"></use>
                                </svg>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <a href="http://tgaystechnology.com/blog">blog</a>
                </li>

                <li>
                  <Link to="/contact">contact</Link>
                </li>

                <li class="top-head-social-icon">
                  <Link to='/popup'>
                    <button type="" class="btn js-message-popup top_header_btn">CONSULT OUR EXPERTS</button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
