import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const sliderSettings = {
	dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,  // 3 seconds
};

function GeetingProjEasy() {
  return (
    <div>
      <section className="crumina-module crumina-module-slider pt100 hm-easy-section">
	        <div className="crumina-module crumina-heading hm-easy_title text-center">
                <h2 className="heading-title">Getting Your Project Started is Easy!</h2>
                <h6 className="heading-sup-title">CHECK THE STEPS BELOW</h6>
            </div>
			<div className='easy_slider'>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div className="swiper-container navigation-bottom" data-effect="fade">
							<Slider {...sliderSettings}>
							<div className="swiper-slide">
								<div className="row">
								<div className="col-lg-4 col-md-12 col-sm-12" data-swiper-parallax="-100">
									<div className="slider-faqs-thumb">
									<img className="utouch-icon" src="svg-icons/dial.svg" alt="image" />
									</div>
								</div>

								<div className="col-lg-7 col-md-12 col-sm-12" data-swiper-parallax="-300">
									<h5 className="slider-faqs-title">SHARE YOUR BUSINESS/PROJECT REQUIREMENT WITH US!</h5>
									<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12">
										<p>We can easily be reached at:</p>
										<ul className="list list--standard">
										<li>
											<svg className="utouch-icon utouch-icon-correct-symbol-1">
											<use xlinkHref="#utouch-icon-correct-symbol-1"></use>
											</svg>
											<a href="#">Phone: (+91) 9971067266, (+91) 9319239730</a>
										</li>
										<li>
											<svg className="utouch-icon utouch-icon-correct-symbol-1">
											<use xlinkHref="#utouch-icon-correct-symbol-1"></use>
											</svg>
											<a href="#">Email: info@tgaystechnology.com, sales@tgaystechnology.com</a>
										</li>
										<li>
											<svg className="utouch-icon utouch-icon-correct-symbol-1">
											<use xlinkHref="#utouch-icon-correct-symbol-1"></use>
											</svg>
											<a href="#">Skype: webapp.development</a>
										</li>
										</ul>
									</div>
									</div>
								</div>
								</div>
							</div>

							<div className="swiper-slide">
								<div className="row">
								<div className="col-lg-4 col-md-12 col-sm-12" data-swiper-parallax="-100">
									<div className="slider-faqs-thumb">
									<img className="utouch-icon" src="svg-icons/chat1.svg" alt="image" />
									</div>
								</div>

								<div className="col-lg-7 col-md-12 col-sm-12" data-swiper-parallax="-300">
									<h5 className="slider-faqs-title">BRIEF YOUR REQUIREMENT</h5>
									<p>
									There is always a Project Manager or a Business Analyst to follow up with you to discuss your Business Requirement. We ask as many questions as we can to get every bit of information from you so that we don't miss anything to understand your expectation. We might take just one discussion call or 3-4 calls depending upon your project length.
									</p>
								</div>
								</div>
							</div>

							<div className="swiper-slide">
								<div className="row">
								<div className="col-lg-4 col-md-12 col-sm-12" data-swiper-parallax="-100">
									<div className="slider-faqs-thumb">
									<img className="utouch-icon" src="svg-icons/devices.svg" alt="image" />
									</div>
								</div>

								<div className="col-lg-7 col-md-8 col-sm-12" data-swiper-parallax="-100">
									<h5 className="slider-faqs-title">GET PROJECT SCOPE, COST, TIMELINE AND MILESTONES PROPERLY DEFINED!</h5>
									<p>
									We review your requirement in details and after a thorough discussion with the team, we define and document the entire scope of the project, the timeline, budget, milestones/sprints, and the deployment plan.
									</p>
									<p>Once the customer approves, we get into a contract to start the project.</p>
									<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-12">
										<div className="crumina-module crumina-info-box info-box--standard">
										<div className="info-box-image display-flex">
											<svg className="utouch-icon utouch-icon-checked">
											<use xlinkHref="#utouch-icon-checked"></use>
											</svg>
											<h6 className="info-box-title">NDA</h6>
										</div>
										<p className="info-box-text">We are open to signing NDA's if required.</p>
										</div>
									</div>
									</div>
								</div>
								</div>
							</div>

							<div className="swiper-slide">
								<div className="row">
								<div className="col-lg-4 col-md-12 col-sm-12" data-swiper-parallax="-100">
									<div className="slider-faqs-thumb">
									<img className="utouch-icon" src="svg-icons/payment-method.svg" alt="image" />
									</div>
								</div>

								<div className="col-lg-7 col-md-12 col-sm-12" data-swiper-parallax="-300">
									<h5 className="slider-faqs-title">SIGNING AN AGREEMENT</h5>
									<p className="weight-bold">The final step to start a Project is signing an agreement.</p>
									<p>
									We understand that your time is really valuable and we do respect it. To make the bonding more adamant, we prefer signing a contract so that we are in a bond of transparency from the beginning of the work.
									</p>
								</div>
								</div>
							</div>

							<div className="swiper-slide">
								<div className="row">
								<div className="col-lg-4 col-md-12 col-sm-12" data-swiper-parallax="-100">
									<div className="slider-faqs-thumb">
									<img className="utouch-icon" src="svg-icons/rocket.svg" alt="image" />
									</div>
								</div>

								<div className="col-lg-7 col-md-12 col-sm-12" data-swiper-parallax="-300">
									<h5 className="slider-faqs-title">GET GOING</h5>
									<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12">
										<p>
										All Initals Set and Done! We are ready to start the new assignment.
										</p>
									</div>
									</div>
								</div>
								</div>
							</div>
							</Slider>
						</div>
						</div>
					</div>
				</div>
			</div>
        
      </section>
    </div>
  );
}

export default GeetingProjEasy;
