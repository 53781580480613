import React from 'react';
import Accordion from './Accordion';

const HomeAccordion = (type) => {
  console.log(type.type)
  var types=type.type
  var accordionData1 = [
    {
      title: 'Easy To Start, Less Paperwork, Less Obligations!',
      content: `		We have less obligations, paper-work and believe more in adequate requirement gathering. An unclear requirement is the reason for a later conflicts. We take care of everything whether it is creating scope, timeline, budget, work plan or a successful deployment.
							 `
    },
    {
      title: 'Save More Money without any Compromise in Product Quality',
      content: `We offer the best possible price that is a win-win for both. To sustain and have growth, every business need to make profits but we never over-quote. At the same time, we don't compromise with the quality of work.
									`
    },
    {
      title: 'Our response is fast, direct, and reliable, assuring you of a stress-free process.',
      content: `We have an in-house team of expert developers, designers, leads and managers. We are open to be contacted on Skype, Google Meetings, WhatsApp and through a direct Phone call. So, reaching to us is fast, direct and easy. 
						`
    },
        {
       title: 'Excellent communication skills and prompt responses.',
      content: `Excellent communication skills and prompt responses.`
    },
    {
      title: 'Complete transparency throughout the project lifecycle.',
      content: `	Complete transparency throughout the project lifecycle.`
    },
    {
      title: 'Clear and comprehensive directions on how the project will be executed.',
      content: `Clear and comprehensive directions on how the project will be executed.`
    },
    {
      title: 'Highly professional and skilled team.',
      content: `Highly professional and skilled team.`
    },
    {
      title: 'Regular progress updates to keep clients informed.',
      content: `Regular progress updates to keep clients informed.`
    },
    
       
  ];

  var accordionData2 = [
    {
      title: 'Time Zone Difference is more of an Advantage',
      content: `	With more than 10 hours time difference, Our Customers living is USA, Canada discuss their requirement with our team before going to bed and check back the status in their morning time.`
    },
    
    {
      title: 'Delivering exceptional value for the clients investment.',
      content: `Delivering exceptional value for the client's investment.`
    },
        {
      title: 'Comprehensive post-sales support to address any bugs or issues that may arise.',
      content: `Comprehensive post-sales support to address any bugs or issues that may arise.`
    },
    {
      title: 'No hidden charges or unexpected billings, ensuring financial clarity.',
      content: `No hidden charges or unexpected billings, ensuring financial clarity.`
    },
    {
      title: 'Strict confidentiality of the projects code, with NDAs and contracts signed if necessary.',
      content: `Strict confidentiality of the project's code, with NDAs and contracts signed if necessary.`
    },
    {
      title: 'Availability for urgent matters, ensuring clients can reach the team at any time.',
      content: `Availability for urgent matters, ensuring clients can reach the team at any time.`
    },
    {
      title: 'Ensure delivery of Secure Applications.',
      content: `Ensure delivery of Secure Applications.`
    },
    
       
  ];
  var accordionData = []
  if(types==1){
    accordionData = accordionData1
  }else{
    accordionData = accordionData2
  }

  return (
    <div>
   
        
      <div className="accordion">
        
        {accordionData.map(({ title, content }) => (
          
          <Accordion title={title} content={content} />
        ))}
      </div>
    </div>
  );
};

export default HomeAccordion;