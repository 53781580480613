import React, { useState } from 'react';
import styles from "../../ourportfolioProject.module.css";
import { Link } from 'react-router-dom';

const projects = {
  website: [
    { src: 'img/portfolio_img/audioitch-245-437.jpg', alt: 'Audio Itch', title: 'Audio itch' },
    { src: 'img/portfolio_img/smartreliefmassage-245-437.jpg', alt: 'Smart Relief Massage', title: 'Smart Relief Massages' },
    { src: 'img/portfolio_img/tommygunartclub-245-437.jpg', alt: 'Tommy Gun Art Club', title: 'Tommy Gun Art Club' },
    { src: 'img/portfolio_img/ezshopit(1).png', alt: 'EZ Shop IT', title: 'EZ Shop IT' },
    { src: 'img/portfolio_img/ezglobalshop.png', alt: 'EZ Global Shop', title: 'EZ Global Shop' },
    { src: 'img/portfolio_img/my-party-peer2peer.png', alt: 'My Party Peer2Peer', title: 'My Party Peer2Peer' },
    { src: 'img/portfolio_img/square-one-london.png', alt: 'Square One London', title: 'Square One London' },
    { src: 'img/portfolio_img/dolly-and-dotty.png', alt: 'Dolly and Dotty', title: 'Dolly and Dotty' },
    { src: 'img/portfolio_img/Canadian-County.png', alt: 'Canadian Country', title: 'Canadian Country' },
  ],
  graphics: [
    { src: 'img/portfolio_img/Graphic/Apparel-style2.png', alt: 'Apparel Style2', title: 'Apparel Style2' },
    { src: 'img/portfolio_img/Graphic/Apparel.png', alt: 'Apparel', title: 'Apparel' },
    { src: 'img/portfolio_img/Graphic/big-festive-sale-2.png', alt: 'Big Festive Sale 2', title: 'Big Festive Sale 2' },
    { src: 'img/portfolio_img/Graphic/big-festive-sale.png', alt: 'Big Festive Sale', title: 'Big Festive Sale' },
    { src: 'img/portfolio_img/Graphic/Electronic-Items.png', alt: 'Electronic Items', title: 'Electronic Items' },
    { src: 'img/portfolio_img/Graphic/laptop-banner.png', alt: 'Laptop', title: 'Laptop' },
    { src: 'img/portfolio_img/Graphic/think-eletonices.png', alt: 'Think Eletonices', title: 'Think Eletonices' },
    { src: 'img/portfolio_img/Graphic/Laptops-&-Accessories.png', alt: 'Laptop & Accessories', title: 'Laptop & Accessories' },
    { src: 'img/portfolio_img/Graphic/tgyas-logo.png', alt: 'Tgays Technology', title: 'Tgays Technology', className: styles.logoItem },
    { src: 'img/portfolio_img/Graphic/vb-logo.png', alt: 'Vibrant Bihar', title: 'Vibrant Bihar', className: styles.logoItem },
    { src: 'img/portfolio_img/Graphic/health1.png', alt: 'Health', title: 'Health', className: styles.logoItem },
    { src: 'img/portfolio_img/Graphic/warthmetrix-logo.png', alt: 'WarthMetrix', title: 'WarthMetrix', className: styles.logoItem },
    { src: 'img/portfolio_img/Graphic/Bml-logo.png', alt: 'BML Logo', title: 'BML Logo', className: styles.logoItem },
    { src: 'img/portfolio_img/Graphic/gplogo2.png', alt: 'GP Logo', title: 'GP Logo', className: styles.logoItem},
],
  figma: [
    { src: 'img/portfolio_img/figma/figma_traval-1.png', alt: 'Travel', title: 'Travel', link: 'https://www.figma.com/design/03Kf5xL1nsLO4Ls13LUJzl/figma-traval?m=auto&t=Ogdnvn0xXjxSsuQ9-6' },
    { src: 'img/portfolio_img/figma/it-solution.png', alt: 'IT Solution', title: 'IT Solution', link: 'https://www.figma.com/design/ousYfVYFnsG8Ox5ZQCOqm9/IT-Company?m=auto&t=Ogdnvn0xXjxSsuQ9-6' },
    { src: 'img/portfolio_img/figma/Nike-Air-Max-UI-Design-1.png', alt: 'Nike Air Max', title: 'Nike Air Max', link: 'https://www.figma.com/design/A4tyDFkTsOUkXYMDUgurrm/Nike-Air-Max-UI-Design?m=auto&t=Ogdnvn0xXjxSsuQ9-6', className: styles.figmaBanner },
    { src: 'img/portfolio_img/figma/Nike-Website-UI-Design-1.png', alt: 'Nike Websit', title: 'Nike Websit', link: 'https://www.figma.com/design/wG0tDORGTF6dqfTCEhgzPm/Nike-Website-UI-Design?m=auto&t=Ogdnvn0xXjxSsuQ9-6', className: styles.figmaBanner },
    { src: 'img/portfolio_img/figma/Spices-1.png', alt: 'Spicesn', title: 'Spices', link: 'https://www.figma.com/design/Q2UpHVPpoGSnqxsauoyneI/Spices?m=auto&t=Ogdnvn0xXjxSsuQ9-6' },
  ],
  webApp: [
    { src: 'img/portfolio_img/wepapp/bolton-login.png', alt: 'Bolton Travel Business CRM', title: 'Bolton Travel Business CRM' },
    { src: 'img/portfolio_img/wepapp/bolton-travel-quotation.png', alt: 'Bolton Travel Quotation CRMl', title: 'Bolton Travel Quotation CRM' },
    { src: 'img/portfolio_img/wepapp/ezshopit(1).png', alt: 'Ezshopit', title: 'Ezshopit' },
    { src: 'img/portfolio_img/wepapp/management-login-form.png', alt: 'Business Management Application', title: 'Business Management Application' },
    { src: 'img/portfolio_img/wepapp/school1.png', alt: 'School Management System', title: 'School Management System' },
    { src: 'img/portfolio_img/wepapp/tax-consulting12.png', alt: 'Oktaxrolls', title: 'Oktaxrolls' },
    { src: 'img/portfolio_img/wepapp/tommygun.png', alt: 'Tommygun Art Club', title: 'Tommygun Art Club' },
    { src: 'img/portfolio_img/wepapp/worthmetrix2.png', alt: 'Worthmetrix', title: 'Worthmetrix' },
  ],
  mobileApp: [
    { src: 'img/hire/eolveai-mobile_resize.png', alt: 'EvolveAI', title: 'EvolveAI' },
    { src: 'img/hire/ezshopit.png', alt: 'Ezshopit', title: 'Ezshopit' },
    
    { src: 'img/hire/twgtea.jpg', alt: 'TWG Tea', title: 'TWG Tea' },
    { src: 'img/hire/go-gro-local2.png', alt: 'Go Gro Local', title: 'Go Gro Local' },
    { src: 'img/hire/login-screen.jpg', alt: 'e-Fitness', title: 'e-Fitness' },
    { src: 'img/hire/fastest-delivery-app.png', alt: 'Your Fastest Delivery App', title: 'Your Fastest Delivery App' },
    { src: 'img/hire/expense-management.png', alt: 'Expense Management', title: 'Expense Management' },
    { src: 'img/hire/peer2peer220-320.jpg', alt: 'Peer2Peer', title: 'Peer2Peer' },
    { src: 'img/hire/Home-city-mobile.png', alt: 'Citytehelka', title: 'Citytehelka' },
    { src: 'img/hire/viralnewsfactory_mobile.png', alt: 'ViralNews Factory', title: 'ViralNews Factory' },
    { src: 'img/hire/saimonacreation-com-mobile.png', alt: 'Saimona Creation', title: 'Saimona Creation' },
  ],
};

const OurPortfolioProject = () => {
  const [activeCategory, setActiveCategory] = useState('website');
  const [modal, setModal] = useState({ isOpen: false, src: '', title: '' });

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const handleOpenModal = (src, title) => {
    setModal({ isOpen: true, src, title });
  };

  const handleCloseModal = () => {
    setModal({ isOpen: false, src: '', title: '' });
  };

  return (
    <div className={styles.postPortfolio}>
      <div className={styles.container}>
        <h4>Features Project</h4>
        <h1 className={styles.headingTitle}>Explore Our Project</h1>
        <ul className={`${styles.catListBgStyle} ${styles.sortingMenu}`}>
          {Object.keys(projects).map((category) => (
            <li
              key={category}
              className={`${styles.catListItem} ${activeCategory === category ? styles.active : ''}`}
              onClick={() => handleCategoryChange(category)}
            >
              <Link to="#" className={styles.catLink}>{category.charAt(0).toUpperCase() + category.slice(1)}</Link>
            </li>
          ))}
        </ul>
        <div className={styles.projectGrid}>
          {projects[activeCategory].map((project, index) => (
            <div
              key={index}
              className={`${styles.projectItem} ${project.className}  ${styles[`${activeCategory}-item`]}`}
              onClick={() => handleOpenModal(project.src, project.title)}
            >
              <img src={project.src} alt={project.alt} />
             <a href={project.link} target="_blank" rel="noopener noreferrer"> 
              <div className={styles.overlay}>{project.title} </div>
              </a>
            </div>
          ))}
        </div>
        {modal.isOpen && (
          <div className={styles.modal}>
            <span className={styles.close} onClick={handleCloseModal}>&times;</span>
            <img className={styles.modalContent} src={modal.src} alt={modal.title} />
            <div className={styles.caption}>{modal.title}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OurPortfolioProject;
