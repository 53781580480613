import React from 'react'
import WordPressAccordion from '../../../includes/Accordionpopup/Wordpress/WordPressAccordion'

function WhyWordPress() {
    return (
        <div>
	<section class="medium-padding120 more-que-sec codei-sectio">
		<div class="container">
			<div class="row">

				<div class="col-lg-6 col-md-12 col-12 col-xxl-6">
					<img src="img/wordpress.img.png" class="react-mobile-img" alt="image"/>
				</div>
				<div class="col-lg-6 col-md-12 col-12 col-xxl-6">

					<div class="crumina-module crumina-heading">
						<h2 class="heading-title">Why<span class="c-primary"> Wordpress</span> for<br/> Website development?</h2>
					</div>
                   <WordPressAccordion/>
				</div>

				
			</div>
		</div>
	</section>
        </div>
    )
}

export default WhyWordPress