import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Slider() {
    return (
        <div id="carouselExampleCaptions" className="carousel slide home_banner_slider_sec" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="img/banner.png" className="d-block w-100 home-img" alt="..." />
                    <div className="carousel-caption d-md-block home-page-slider-sec">
                        <h5>Trusted for Custom CRM Solutions, SaaS, Websites and Mobile Apps<br /></h5>
                        <p>High failure rates often stem from businesses becoming overly focused on daily operations, neglecting strategic revenue and profit generation. We offer software solutions that automate core business functions, empowering you to dedicate your time and expertise to driving growth.</p>
                        {/*<Link to='/popup'>
                            <button type="" className="btn btn-primary js-message-popup">Get Started Now</button>
                        </Link>*/}
                        <div className="list_banner_text">
                            <ul className="d-flex flex-wrap">
                                <li><span>10 Custom CRM's</span></li>
                                <li><span>500+ Websites</span></li>
                                <li><span>15 Mobile Apps</span></li>
                                <li><span>5 SaaS Apps</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="img/mobile-banner.png" className="d-block w-100 home-img" alt="..." />
                    <div className="carousel-caption d-md-block home-page-slider-sec">
                        <h5>Building the Future: Our Powerful Tech Stack</h5>
                        <p>We leverage cutting-edge technologies <b>(WordPress, Codeigniter, Laravel, Python, Django, Generative AI, Node.js, React, Next.js, React Native, Flutter, Figma, Canva, MySQL, MongoDB)</b> to craft exceptional digital experiences.</p>
                        {/*<Link to='/popup'>
                            <button type="" className="btn btn-primary js-message-popup">Get Started Now</button>
                        </Link>*/}
                        <div className="list_banner_text">
                            <ul className="d-flex flex-wrap">
                                <li><span>50+ Wordpress Websites</span></li>
                                <li><span>5 MERN Stack App</span></li>
                                <li><span>5 Django Web App</span></li>
                                <li><span>15 React Native/Flutter Mobile App</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="img/banner-1.png" className="d-block w-100 home-img" alt="..." />
                    <div className="carousel-caption d-md-block home-page-slider-sec empowering_industries">
                        <h5>100+ Client Success Stories Across 23 Countries</h5>
                        <p>We craft innovative solutions that drive success in <b>Finance, Travel & Tourism, Healthcare, E-commerce, and Education.</b> We have got a Proven Track Record of Serving 100+ Clients Across 23 Countries</p>
                        {/*<Link to='/popup'>
                            <button type="" className="btn btn-primary js-message-popup">Get Started Now</button>
                        </Link>*/}
                        <div className="list_banner_text">
                            <ul className="d-flex flex-wrap">
                                <li><span>3 Fintech Web App</span></li>
                                <li><span>5 Travel Web App</span></li>
                                <li><span>5 Healthcare CRM</span></li>
                                <li><span>30+ E-Commerce Websites</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Slider;
