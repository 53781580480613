import React from 'react'

function WhyChooseE3() {
    return (
        <div>
         	
	<section class="bg-secondary-color background-contain web-totle our_solution_section bg-dark-blue">

		<div class="container">
			<div class="counters">
			
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="crumina-module crumina-heading hm-easy_title text-center">
							<h2 class="heading-title">Our Solutions</h2>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box">
							<div class="counter-numbers counter ">
								<span>Custom Web Development</span>
								{/* <div class="units">+</div> */}
							</div>

							<ul>
								<li>- Custom B2B/B2C E-commerce Platforms</li>
								<li>- Tour & Travel Customer/Quotation/Supplier Management</li>
							</ul>
							{/* <h5 class="counter-title">Website Designed</h5> */}
						</div>
						<div class="icons-dv blue text-center">
							<ion-icon name="laptop-outline"></ion-icon>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box">
							<div class="counter-numbers counter ">
								<span>SaaS (Software as a Service) Solutions</span>
							</div>
							<ul>
								<li>- Custom CRM Software</li>
								<li>- Project Management Tool</li>
								<li>- Invoicing Systems</li>
								<li>- HR Management Systems</li>
								<li>- Customer Support Systems</li>
							</ul>
						</div>
						<div class="icons-dv yellow text-center">
						<ion-icon name="logo-web-component"></ion-icon>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box">
							<div class="counter-numbers counter ">
								<span>Mobile App Development</span>
							</div>
							<ul>
								<li>- E-commerce</li>
								<li>- Healthcare & Fitness Apps</li>
								<li>- Food Delivery Apps</li>
								<li>- Fintech Apps, and on-demand services</li>
							</ul>
						</div>
						<div class="icons-dv red text-center">
							<ion-icon name="phone-portrait-outline"></ion-icon>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box">
							<div class="counter-numbers counter ">
								<span>AI-Powered Solutions</span>
								{/* <div class="units">+</div> */}
							</div>
							<ul>
								<li>- Chatbots</li>
								<li>- Recommendation engines</li>
								<li>- Fraud detection systems</li>
								<li>- AI-based customer service tools</li>
							</ul>
						</div>
						<div class="icons-dv green text-center">
						   <ion-icon name="grid-outline"></ion-icon>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box">
							<div class="counter-numbers counter ">
								<span>E-commerce Solutions</span>
							</div>
							<ul>
								<li>- Complete e-commerce platforms</li>
								<li>- Multi-vendor marketplaces</li>
								<li>- B2B/B2C platforms</li>
								<li>- POS Billing Software</li>
							</ul>
						</div>
						<div class="icons-dv green text-center">
						   <ion-icon name="storefront-outline"></ion-icon>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box">
							<div class="counter-numbers counter ">
								<span>ERP (Enterprise Resource Planning) Solutions</span>
							</div>
							<ul>
								<li>- Inventory management systems</li>
								<li>- Financial management software</li>
								<li>- Healthcare Software</li>
							</ul>
						</div>
						<div class="icons-dv green text-center">
						   <ion-icon name="contract-outline"></ion-icon>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box end_box">
							<div class="counter-numbers counter ">
								<span>Cloud-Based Solutions</span>
							</div>
							<ul>
								<li>- Cloud-based storage</li>
								<li>- Cloud computing services</li>
								<li>- SaaS integrations</li>
								<li>- Cloud migration services</li>
							</ul>
						</div>
						<div class="icons-dv green text-center">
						    <ion-icon name="layers-outline"></ion-icon>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box end_box">
							<div class="counter-numbers counter ">
								<span>Fintech Applications</span>
							</div>
							<ul>
								<li>- Payment gateways</li>
								<li>- Digital wallets</li>
								<li>- Peer-to-peer lending platforms</li>
								<li>- Robo-advisors</li>
							</ul>
						</div>
						<div class="icons-dv green text-center">
						    <ion-icon name="layers-outline"></ion-icon>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box end_box">
							<div class="counter-numbers counter ">
								<span>EdTech Platforms</span>
							</div>
							<ul>
								<li>- Learning Management Systems (LMS)</li>
								<li>- Online Course Platforms</li>
								<li>- Assessment and Testing Platforms</li>
								<li>- VR/AR Learning Platforms </li>
							</ul>
						</div>
						<div class="icons-dv green text-center">
						   <ion-icon name="snow-outline"></ion-icon>
						</div>
					</div>

				</div>
			</div>
		</div>

	</section>
        </div>
    )
}

export default WhyChooseE3