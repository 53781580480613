import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import HomeFeaturedClint from '../../includes/slider/HomeFeaturedClint';

const testimonials = [
  {
    imgSrc: "img/rich.jpg",
    text: "It was fantastic 7 years working with the Team. I didn't even realize, we spent more than 7 years together. Timezone Difference or Remote work was never a barrier.",
    author: "Richard Bonitz",
    company: "Director, Endless River Technology"
  },
  {
    imgSrc: "img/francis.png",
    text: "They turned to be one of our best choice as a Technology partner. They have quick turn-around time easily accessible, cost-effective and has a great value in their work.",
    author: "Francis Wills",
    company: "Managing Partner, KIA-GTS"
  },
  {
    imgSrc: "img/chris.png",
    text: "From the half way round the world, we found an excellent IT Offshore Partner. We were looking for a Company which can take care of our 30+ existing websites and can also develop new websites.",
    author: "Chris Shrinkle",
    company: "Managing Director, Gogiro Cleveland LLC"
  }
];

function ClientsAboutus() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,  // 3 seconds
    nextArrow: <div className="btn-next"><svg className="utouch-icon icon-hover utouch-icon-arrow-right-1"><use xlinkHref="#utouch-icon-arrow-right-1"></use></svg><svg className="utouch-icon utouch-icon-arrow-right1"><use xlinkHref="#utouch-icon-arrow-right1"></use></svg></div>,
    prevArrow: <div className="btn-prev"><svg className="utouch-icon icon-hover utouch-icon-arrow-left-1"><use xlinkHref="#utouch-icon-arrow-left-1"></use></svg><svg className="utouch-icon utouch-icon-arrow-left1"><use xlinkHref="#utouch-icon-arrow-left1"></use></svg></div>,
  };

  return (
    <div>
      <div className="testimonials-section home_testimonials-section">
        <div className="container">
          <div className="crumina-module crumina-heading text-center">
		  <h3 class="sub-title">Testimonial</h3>
		  <h2 class="heading-title">What our <span class="c-primary">Clients say about us?</span></h2>
          </div>
              <section className="crumina-module crumina-module-slider testimonial_slider_mixed_box cloud-center navigation-center-both-sides">
                <div className="container">
                  <Slider {...settings}>
                        {testimonials.map((testimonial, index) => (
                          <div key={index} className="crumina-module crumina-testimonial-item testimonial-item-author-top">
                            <div className="testimonial-img-author">
                              <img src={testimonial.imgSrc} alt="avatar" />
                            </div>
                            <div className="testomonial_box">
                              <h6 className="testimonial-text">
                                {testimonial.text}
                              </h6>
                              <div className="author-info-wrap">
                                <div className="author-info">
                                  <a className="h6 author-name">{testimonial.author}</a>
                                  <div className="author-company">{testimonial.company}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                </div>
              </section>
            {/* <div className="col-lg-4 col-md-12 col-12 col-xxl-4">
              <img src="img/successful-young.png" alt="testimonial-mg" className="testimonial-mg" />
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default ClientsAboutus;
