import React from "react";
import { Link } from "react-router-dom";
import ShowWorkPortfolio from "../WorkPortfolioSection/ShowWorkPortfolio";
function PromoBlock() {
  return (
    <div>
      <br />
      <br />
      <ShowWorkPortfolio />
      <br />
      <br />
    </div>
  );
}

export default PromoBlock;
