import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import HomeAccordion from '../../includes/Accordionpopup/Home/HomeAccordion';
function AboutUs() {
    return (

        <div>


	<section class="medium-padding120 hm-software-secvice why_us_sec background-contain">
		<div class="container">
		<div class="shape3"><img src="img/intro-v1-shape3.png" alt=""/></div>
		<div class="crumina-module crumina-heading"> 
						<h2 class="heading-title">What Makes <span class="c-primary">TGAYS Technology Unique?</span></h2>
					</div>
			<div class="row">

				<div class="col-lg-4 col-md-6 col-12 col-xxl-4">
					{/* <img src="img/app-developmen.png" alt="image"/> */}
                    <HomeAccordion type={1}/>
				</div>
				<div class="col-lg-4 col-md-6 col-12 col-xxl-4">
					<div class="accordion_img">
					   <img src="img/acco-pic.png" alt="image"/>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-12 col-xxl-4 according_right_box">
                    <HomeAccordion type={2}/>
				</div>

				
			</div>
		</div>
	</section>

        </div>
    )
}

export default AboutUs