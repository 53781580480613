import React from 'react'

function ClientFeedback() {
  return (
    <div>
        {/* <section class="background-contain pt100 web-solution-section help-them-sec">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
					<div class="crumina-module ">
						<div class="img-thumb">
							<img src="img/help-them.png" alt="help-them"/>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 about-us-sec">
					
						<div class="crumina-module crumina-heading">
							<div class="about-us-hedding">
								<h2 class="heading-title">How do we help <br/><span class="c-primary"> them? </span></h2>
							</div>
							<h6 class="slider-content-text" data-swiper-parallax="-200">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
						    </h6>
						    <h6 class="slider-content-text" data-swiper-parallax="-200">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
						    </h6>
						</div>
				</div>
			</div>
		</div>
	</section> */}

	{/* <div class="testimonials-section">
		<div class="container">
			<div class="crumina-module crumina-heading">
				<h2 class="heading-title">Client's  <span class="c-primary">Experience/Feedback:</span></h2>
			</div>
			<div class="row">
				<div class="col-lg-12 col-md-12 col-12 col-xxl-12">
					<section class="crumina-module bg-4 cloud-center medium-padding100">
						<div class="container">
							<div class="row">
								<div class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-sm-offset-0">
									<div class="swiper-container" data-effect="fade">
										<div class="swiper-wrapper">
											<div class="crumina-module crumina-testimonial-item testimonial-item-author-top ">

												<div class="testimonial-img-author" data-swiper-parallax="-100">
													<img src="img/rich.jpg" alt="avatar"/>
												</div>

												<h6 class="testimonial-text" data-swiper-parallax="-300">
													It was fantastic 7 years working with Jamal and Team. I didn't even realize, we spent more than 7 years together. Timezone Difference or Remote work was never a barrier. I highly recommend them.
												</h6>

												<div class="author-info-wrap" data-swiper-parallax="-100">

													<div class="author-info">
														<a href="#" class="h6 author-name">Richard Bonitz</a>
														<div class="author-company">Director, Endless River Technology</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					
					</section>
				</div>
			</div>
		</div>
	</div> */}
    </div>
  )
}

export default ClientFeedback